import { user } from "@fscrypto/domain";
import { BehaviorSubject } from "rxjs";
import { useSubscription } from "~/hooks/useSubscription";

export const featureFlags$$ = new BehaviorSubject<Record<user.FeatureFlag, string | boolean>>({
  "internal-product": false,
  "sf-early-access": false,
  "viz-2": false,
  "studio-redesign": false,
  "dashboard-embed": false,
  "user-score-surfacing-v1": false,
  "dashboard-v3": false,
  "dashboard-viewers": false,
  "dashboard-comments": false,
  "dashboard-tags": false,
});

export const useAllFeatureFlags = () => {
  const flags = useSubscription(featureFlags$$);
  return flags;
};

export const useFeatureFlag = (flag: user.FeatureFlag) => {
  const flags = useAllFeatureFlags();
  return flags[flag] ?? false;
};

export function getFeatureFlagValue(flag: user.FeatureFlag): string | boolean {
  const currentFlags = featureFlags$$.getValue();
  return currentFlags[flag] ?? false;
}
